import { create } from "zustand"

import { ApiState, getInitialState } from "../store"
import { axiosApi } from "./axios"
import { CreateDoctorForm } from "../components/Modals/CreateDoctor"

type GetUnassignedDoctors = ApiState<any, any, any>

const useGetUnassignedDoctors = create<GetUnassignedDoctors>((set) => ({
  ...getInitialState<any, any, any>(
    {
      method: "GET",
      url: "/api/doctor/fetch-unassigned",
    },
    set
  ),
}))


type GetDoctorsInGroup = ApiState<{ groupId: string }, any, any>

const useGetDoctorsInGroup = create<GetDoctorsInGroup>((set) => ({
  ...getInitialState<{ groupId: string }, any, any>({}, set),
  fetchFn: async (body) => {
    let data: any = null as any
    let error: any = null
    let isError = false
    let isLoading = false
    let isSuccess = false

    try {
      set({
        isLoading: true,
        isError: false,
        isSuccess: false,
        data: null,
        error: null,
      })
      isLoading = true
      const res = await axiosApi({
        url: `/api/group/doctors/group?groupId=${body?.data?.groupId}`,
        method: "GET",
      })
      set({ data: res.data, isSuccess: true })
      data = res.data
      isSuccess = true
    } catch (err: any) {
      set({ isError: true, error: err, isSuccess: false, data: null })
      isError = true
      error = err
      isSuccess = false
    } finally {
      set({ isLoading: false })
      isLoading = false
    }

    return {
      unwrap: () => {
        return { data, error, isError, isLoading, isSuccess }
      },
    }
  },
}))

type SetLeaves = ApiState<any, any, any>

const useSetLeaves = create<SetLeaves>((set) => ({
  ...getInitialState<any, any, any>(
    {
      method: "POST",
      url: "/api/doctor/leave",
    },
    set
  ),
}))

// type AddDoctor = ApiState<CreateDoctorForm, any, any>

// const useAddDoctor = create<AddDoctor>((set) => ({
//   ...getInitialState<CreateDoctorForm, any, any>(
//     {
//       method: "POST",
//       url: "/api/doctor/create",
//     },
//     set
//   ),
// }))

const doctor = {
  useGetDoctorsInGroup,
  useGetUnassignedDoctors,
  useSetLeaves,
  // useAddDoctor,
}

export default doctor
