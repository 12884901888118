import { useState } from "react";
import { DateTime } from "luxon";

import RoundedRightArrowButton from "../../Icons/RoundedRightArrowButton";
import OuterWindow from "../OuterWindow";
import MonthCalendar from "../UserDataWindowComponents/MonthCalendar";
import UpcomingShiftCard from "../UserDataWindowComponents/UpcomingShiftCard";
import DoctorInfoWindowDetails from "../UserDataWindowComponents/DoctorInfoWindowDetails";
import { useLocation } from "../../../store/location.store";
import { useGetDoctorStatsQuery } from "../../../api/rosterApi";
import { TIME_ZONE } from "../../../constants";
import { useEstimationContext } from "../../../hooks/context/useEstimationContext";
import useMonthCalendar from "../../../hooks/useMonthCalendar";

type UserDataProps = {
  activeDate: Date;
  name: string;
  email: string;
  phoneNumber?: string;
  efficiency: number;
  experience: number;
  groups: any[];
  subGroups: any[];
  tags: any[];
};

const UserDataWindow = ({
  activeDate,
  name,
  email,
  phoneNumber,
  efficiency,
  experience,
  groups,
  subGroups,
  tags,
}: UserDataProps) => {
  const [slide, setSlide] = useState(0);

  const [selectedDateData, setSelectedDateData] = useState<any | undefined>(
    undefined
  );

  const { dataDoctor } = useEstimationContext();

  const dateTime = DateTime.fromJSDate(activeDate).setZone(TIME_ZONE);

  const { month, year, calenderBack, calenderNext } = useMonthCalendar({
    initialMonth: dateTime.get("month"),
    initialYear: dateTime.get("year"),
  });

  const {
    isLoading: isDoctorStatsLoading,
    isFetching: isDoctorStatsFetching,
    data: doctorStatsData,
  } = useGetDoctorStatsQuery(
    {
      seniority: dataDoctor?.seniority,
      month: month,
      year: year,
    },
    { skip: !dataDoctor }
  );

  return (
    <OuterWindow
      className="bg-secondary text-white h-full rounded-xl flex flex-col"
      title={name}
      headerClassName="flex justify-between px-4 gap-4"
      headerChildNode={
        <div className="flex items-center gap-4">
          <div
            onClick={() => {
              if (slide > 0) setSlide(slide - 1);
            }}
          >
            <RoundedRightArrowButton
              isActive={slide === 1}
              className="rotate-180 cursor-pointer"
            />
          </div>
          <div
            onClick={() => {
              if (slide < 1) setSlide(slide + 1);
            }}
          >
            <RoundedRightArrowButton
              isActive={slide === 0}
              className="cursor-pointer"
            />
          </div>
        </div>
      }
      bodyClass={`flex-grow ${slide === 0 ? "grid grid-cols-2 gap-4" : ""}`}
      bodyChildNode={
        <>
          {slide === 0 && dataDoctor && (
            <>
              <MonthCalendar
                month={month}
                year={year}
                calenderBack={calenderBack}
                calenderNext={calenderNext}
                doctorId={dataDoctor.id}
                setSelectedDateData={setSelectedDateData}
                selectedDateData={selectedDateData}
                initialDate={activeDate}
              />
              <UpcomingShiftCard
                stat={doctorStatsData?.[dataDoctor.id]}
                isLoading={isDoctorStatsFetching || isDoctorStatsLoading}
                selectedSchedule={selectedDateData?.schedules[0]}
                isDayOff={selectedDateData?.isDayOff}
                location={
                  selectedDateData?.schedules[0]?.shift?.location?.shortLabel
                }
              />
            </>
          )}
          {slide === 1 && (
            <DoctorInfoWindowDetails
              email={email}
              phone={phoneNumber ?? "No Phone Number Added"}
              experience={experience}
              effeciency={efficiency}
              groups={groups}
              subGroups={subGroups}
              tags={tags}
            />
          )}
        </>
      }
    />
  );
};

export default UserDataWindow;
