import { motion } from "framer-motion";
import { toast } from "react-toastify";
import { useEffect, useMemo, useRef, useState } from "react";
import { stringSimilarity } from "string-similarity-js";
import { useNavigate } from "react-router-dom";
import { useDoctors } from "./DoctorFilterWindow/data";
import SlotPill from "./SlotPill";

import HorizontalScrollContainer from "../HorizontalScrollContainer";
import RoundedTickOutline from "../Icons/RoundedTickOutline";
import RoundedCancelOutline from "../Icons/RoundedCancelOutline";
import Rivet from "../Icons/Rivet";

import { useSeniority } from "../../store/seniority.state";
import { useGetScheduleLabelsQuery } from "../../api/preferencesApi";
import {
  useCreateScheduleMutation,
  useUpdateScheduleMutation,
} from "../../api/rosterApi";
import handleResponse from "../../utils/handleResponse";
import { Filter } from "../../interface/shift";
import isStringArrayEqual from "../../utils/stringArrayEq";
import { useUpdateSlotLabelsMutation } from "../../api/shiftsApi";
import { Doctor } from "../../interface/doctor";
import useKey from "../../hooks/useKey";
import { Schedule } from "@/interface/schedule";

const getName = (doctor: {
  doctor: Doctor;
  scoreName: number;
  scoreNickName: number;
}): string => {
  const type = doctor.scoreNickName >= doctor.scoreName ? "nickName" : "name";
  return doctor.doctor.user[type] ?? "";
};

export type InputSlotMode = "doctor" | "filter" | "close";

const InputSlot = ({
  setMode,
  shiftId,
  schedule,
  filter,
  labels,
  slotIndex,
  onScheduleAdd,
  triggerOnEnter,
}: {
  setMode: (mode: InputSlotMode) => void;
  shiftId: string;
  schedule: Schedule | undefined;
  filter: Filter | undefined;
  labels?: any[];
  slotIndex: number;
  onScheduleAdd: () => void;
  triggerOnEnter: (index: number) => void;
}) => {
  const [query, setQuery] = useState<string>(
    (schedule?.doctor as Doctor).user?.nickName ??
      (schedule?.doctor as Doctor).user?.name ??
      ""
  );
  const [selectedLabels, setSelectedLabels] = useState<string[]>(
    labels?.map((label) => label._id) ?? []
  );

  const navigate = useNavigate();

  const { activeId: activeSeniorityId } = useSeniority();

  const [updateSlotLabels, { isLoading: isUpdateSlotLabelsLoading }] =
    useUpdateSlotLabelsMutation();

  const inputRef = useRef<null | HTMLInputElement>(null);
  const lockAdd = useRef<boolean>(false);

  const { isKeyPressed, setKeyPressed } = useKey(inputRef, [
    "Tab",
    "Enter",
    "Escape",
  ]);

  const { isLoading: isDoctorsLoading, doctors } = useDoctors({
    shiftId,
    type: "slot",
  });

  const doctorsToSearch = doctors?.filter((doctor: Doctor) => doctor.canAssign);

  if (schedule?.doctor) {
    doctorsToSearch.push(schedule.doctor);
  }

  const { data: scheduleLabels, isLoading: isScheduleLabelLoading } =
    useGetScheduleLabelsQuery({
      seniority: activeSeniorityId,
    });

  const [createSchedule, { isLoading: isCreateScheduleLoading }] =
    useCreateScheduleMutation();

  const [updateSchedule, { isLoading: isUpdateScheduleLoading }] =
    useUpdateScheduleMutation();

  const hintDoctor = useMemo(() => {
    if (query !== "") {
      const nickNameDoctor = {
        doctor: doctorsToSearch?.filter((doctor: Doctor) =>
          doctor.user.nickName?.toLowerCase().startsWith(query.toLowerCase())
        )[0],
        type: "nickName",
      };

      if (nickNameDoctor.doctor) {
        return nickNameDoctor;
      }

      const nameDoctor = {
        doctor: doctorsToSearch?.filter((doctor: Doctor) =>
          doctor.user.name.toLowerCase().startsWith(query.toLowerCase())
        )[0],
        type: "name",
      };

      if (nameDoctor.doctor) {
        return nameDoctor;
      }
    }
    return { doctor: undefined, type: "none" };
  }, [doctors, query]);

  const autoCorrectDoctors = useMemo(() => {
    if (query !== "" && !hintDoctor.doctor) {
      const newDoctors:
        | Array<{
            doctor: Doctor;
            scoreName: number;
            scoreNickName: number;
          }>
        | undefined = doctorsToSearch?.map((doctor: Doctor) => ({
        doctor,
        scoreNickName: stringSimilarity(
          query.toLowerCase(),
          doctor.user.nickName?.toLowerCase() ?? "",
          query.length < 4 ? 1 : 2
        ),
        scoreName: stringSimilarity(
          query.toLowerCase(),
          doctor.user.name.toLowerCase(),
          query.length < 4 ? 1 : 2
        ),
      }));

      if (!newDoctors) {
        return;
      }

      newDoctors.sort(
        (
          a: { scoreName: number; scoreNickName: number },
          b: { scoreName: number; scoreNickName: number }
        ) => {
          return (
            b.scoreName + b.scoreNickName - (a.scoreName + a.scoreNickName)
          );
        }
      );

      return newDoctors.filter((doctor) => {
        return (doctor.scoreName + doctor.scoreNickName) / 2 > 0.1;
      });
    }

    return undefined;
  }, [doctors, hintDoctor, query]);

  const autoCompleteText: string | undefined =
    query !== ""
      ? hintDoctor.doctor?.user?.[hintDoctor.type]?.slice(query.length)
      : undefined;

  useEffect(() => {
    if (isKeyPressed["Tab"] && hintDoctor.doctor) {
      setQuery(hintDoctor.doctor?.user?.[hintDoctor.type]);
      return;
    }
    if (
      isKeyPressed["Tab"] &&
      autoCorrectDoctors &&
      autoCorrectDoctors.length > 0
    ) {
      setQuery(getName(autoCorrectDoctors[0]));
      return;
    }
    if (isKeyPressed["Enter"]) {
      handleSubmit(() => {
        onScheduleAdd();
        setMode("close");
        triggerOnEnter(slotIndex);
      });
      return;
    }
    if (isKeyPressed["Escape"]) {
      setMode("close");
      resetState();
      setKeyPressed({ ...isKeyPressed, Escape: false });
    }
  }, [hintDoctor, isKeyPressed, autoCorrectDoctors]);

  const handleSubmit = async (successFunction: () => void) => {
    if (!lockAdd.current) {
      lockAdd.current = true;
      const toAddLabels =
        (!labels && selectedLabels.length > 0) ||
        (labels &&
          !isStringArrayEqual(
            labels.map((label) => label._id),
            selectedLabels
          ));

      await Promise.all([
        // change this to update slot (creates or updates (schedules, slotfilters and slot labels)) - which will assign a schedule and update slot labels too. (if going the single route way)
        (async () => {
          if (toAddLabels) {
            const response = await updateSlotLabels({
              shiftId,
              labels: selectedLabels,
              slotIndex: slotIndex,
            });
            handleResponse(
              response,
              "Schedule Labels Updated.",
              successFunction
            );
          }
        })(),
        (async () => {
          if (
            hintDoctor.doctor &&
            hintDoctor.doctor._id !== (schedule?.doctor as Doctor)?._id &&
            hintDoctor.doctor.canAssign
          ) {
            if (schedule) {
              const response = await updateSchedule({
                scheduleId: schedule._id,
                doctorId: hintDoctor.doctor._id,
                slotFilterId: filter?._id,
              });
              handleResponse(response, "Schedule updated", successFunction);
            } else {
              const response = await createSchedule({
                shiftId,
                doctorId: hintDoctor.doctor._id,
                slotIndex: slotIndex,
              });
              handleResponse(response, "Schedule added", successFunction);
            }
          } else if (!toAddLabels) {
            toast.warning(
              "Invalid doctor name, please press tab or change name",
              {
                toastId: "invalid-doctor-name",
              }
            );
          }
        })(),
      ]);

      lockAdd.current = false;
    }
  };

  useEffect(() => {
    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  }, []);

  const isAutoCorrect = autoCorrectDoctors && autoCorrectDoctors.length > 0;

  const resetState = () => {
    setQuery("");
    setSelectedLabels([]);
  };

  return (
    <motion.div
      key={`${slotIndex}-doctor-input`}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.1 }}
      className={`bg-lightGreen3 ${
        isCreateScheduleLoading ||
        isUpdateScheduleLoading ||
        isUpdateSlotLabelsLoading
          ? "animate-pulseFast"
          : ""
      } rounded-lg w-full p-3 relative`}
    >
      <div
        className={`flex justify-between border-b-[0.4px] border-b-[#BDBDBD] pb-2`}
      >
        {" "}
        <div className={`flex`}>
          <div className={`mr-2 w-8 h-[34px] flex items-center`}>
            <div
              className={`flex items-center cursor-pointer justify-center px-1.5 h-[110%] w-full rounded`}
              onClick={() => {
                setMode("filter");
              }}
            >
              <Rivet fillColor={"#67823A"} />
            </div>
          </div>
        </div>
        <div
          className={`flex relative cursor-text overflow-hidden`}
          onClick={() => {
            inputRef.current?.focus();
          }}
        >
          {!isDoctorsLoading && (
            <>
              <input
                disabled={isDoctorsLoading}
                className={`text-black w-[90%] outline-none font-medium text-base ${
                  isDoctorsLoading
                    ? "animate-pulseFast bg-lightGreen2"
                    : "bg-lightGreen3"
                }`}
                value={query}
                ref={inputRef}
                onChange={(event) => setQuery(event.target.value)}
              />
              {autoCompleteText && (
                <div className="absolute flex mt-[5px]">
                  <div
                    className={`font-medium text-base invisible ${
                      query?.[query.length - 1] === " " ? "mr-1" : ""
                    }`}
                  >
                    {query}
                  </div>
                  <div
                    className={`bg-slate-600 text-white font-medium flex items-center text-base w-fit whitespace-nowrap`}
                  >
                    {autoCompleteText}
                  </div>
                </div>
              )}
            </>
          )}
        </div>
        {!isAutoCorrect && (
          <div className="flex items-center w-[40px] justify-between mr-2">
            <div
              className="flex items-center justify-between cursor-pointer"
              onClick={() => {
                setMode("close");
                resetState();
              }}
            >
              <RoundedCancelOutline expand={true} />
            </div>
            <div
              className="flex items-center justify-between cursor-pointer"
              onClick={() =>
                handleSubmit(() => {
                  onScheduleAdd();
                  setMode("close");
                })
              }
            >
              <RoundedTickOutline expand={true} />
            </div>
          </div>
        )}
      </div>
      {!isAutoCorrect ? (
        !isScheduleLabelLoading ? (
          scheduleLabels && scheduleLabels.length > 0 ? (
            <HorizontalScrollContainer
              className={`mt-4 mb-1 mx-1 !gap-x-1 min-h-[35px]  ${
                isScheduleLabelLoading
                  ? "rounded-lg animate-pulseFast bg-lightGreen2"
                  : ""
              }`}
            >
              {scheduleLabels?.map(
                (label: { label: string; colorCode: string; _id: string }) => (
                  <SlotPill
                    label={{
                      label: label.label,
                      colorCode: label.colorCode,
                      key: label._id,
                    }}
                    isActive={selectedLabels.includes(label._id)}
                    onClick={() => {
                      if (selectedLabels.includes(label._id)) {
                        setSelectedLabels([
                          ...selectedLabels.filter((id) => id !== label._id),
                        ]);
                      } else if (selectedLabels.length < 6) {
                        setSelectedLabels([...selectedLabels, label._id]);
                      }
                      inputRef.current?.focus();
                    }}
                  />
                )
              )}
            </HorizontalScrollContainer>
          ) : (
            <div className="text-base font-semibold w-full mt-4 mb-1">
              <span
                className="bg-gray drop-shadow rounded-md text-lg font-bold px-2 mr-1 cursor-pointer"
                onClick={() => {
                  navigate("/dashboard/preferences");
                }}
              >
                +
              </span>
              Add new label
            </div>
          )
        ) : (
          <></>
        )
      ) : (
        <div className="h-[43px] w-full"></div>
      )}
      {isAutoCorrect && (
        <div
          className={
            "font-medium text-[10px] overflow-y-scroll no-scrollbar h-20 absolute top-1 right-[8%]"
          }
        >
          {autoCorrectDoctors.map((doctor) => (
            <div
              key={doctor.doctor._id}
              className="bg-white rounded-sm text-black my-1 cursor-pointer w-fit mx-auto p-1"
              onClick={() => {
                setQuery(getName(doctor));
                setTimeout(() => {
                  inputRef.current?.focus();
                }, 0);
              }}
            >
              {getName(doctor)}
            </div>
          ))}
        </div>
      )}
    </motion.div>
  );
};

export default InputSlot;
