import { useEffect, useRef, useState } from "react";
import { InfinitySpin } from "react-loader-spinner";

import {
  GROUP_COLORS,
  Key,
  ROW_HEIGHT,
  SENIORITIES,
  TimeBlock,
} from "@/constants";
import { useTableViewContext } from "@/hooks/context/useTableViewContext";
import { useSeniority } from "@/store/seniority.state";
import { Doctor } from "@/interface/doctor";
import debouncer from "@/utils/debouncer";

import { useDoctors } from "./hooks/data";
import Sort from "../Icons/Sort";
import DoctorCard from "./DoctorCard";
import HorizontalScrollContainer from "../HorizontalScrollContainer";
import { useGetTagsQuery } from "@/api/doctorApi";
import { useGetGroupsQuery } from "@/api/groupApi.ts";
import CarotTwo from "../Icons/CarotTwo";
import useKey from "@/hooks/useKey";
import GridTwo from "../Icons/GridTwo";
import ChatBubbleStar from "../Icons/ChatBubbleStar";
import { AnimatePresence, motion } from "framer-motion";
import HalfArrow from "../Icons/HalfArrow";

const SideBar = () => {
  const {
    scrollRef,
    onScrollHandlers,
    doctorSort,
    setDoctorSort,
    doctorFilter,
    setDoctorFilter,
  } = useTableViewContext();

  // refactor the filter bars to different components.

  const { activeId: activeSeniorityId } = useSeniority();

  const [sideBarState, setSidebarState] = useState<"sort" | "groups" | "tags">(
    "sort"
  );

  const [activeDrawerGroup, setActiveDrawerGroup] = useState<string>("");

  const { data: tags, isLoading: isTagsLoading } = useGetTagsQuery({});

  const { isLoading: isGroupsLoading, data: groupsAll } = useGetGroupsQuery({
    seniority: 4,
    includeDoctors: false,
  });

  const groups = groupsAll?.filter(
    (group: { seniority: { id: number }; _id: string }) =>
      !activeSeniorityId || activeSeniorityId === group.seniority.id
  );

  const divRefs = useRef<Array<HTMLDivElement | null>>([]);

  const divContainer = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (groupsAll) {
      divRefs.current = Array(
        groupsAll?.filter(
          (group: { seniority: { id: number }; _id: string }) =>
            !activeSeniorityId || activeSeniorityId === group.seniority.id
        )
      ).fill(null);
    }
  }, [groupsAll]);

  const activeSeniority = SENIORITIES.find(
    (seniority) => seniority.id === activeSeniorityId
  );

  const [_, setTriggerRender] = useState(false);

  const {
    doctors,
    isLoading: isDoctorsLoading,
    isFetching: isDoctorsFetching,
  } = useDoctors();

  useEffect(() => {
    if (scrollRef && scrollRef.current.divSideBar) {
      const func = debouncer(50, () => setTriggerRender((tR) => !tR));
      const scrollHandler = () => {
        func();
        onScrollHandlers.sideBar();
      };

      scrollRef.current.divSideBar.addEventListener("scroll", scrollHandler);
      const elemCopy = scrollRef!.current.divSideBar;

      return () => {
        elemCopy?.removeEventListener("scroll", scrollHandler);
      };
    }
  }, [onScrollHandlers.sideBar, scrollRef]);

  const getSeniority = (groupSeniority: number) => {
    return (
      activeSeniority ??
      SENIORITIES.find((seniority) => groupSeniority === seniority.id)
    );
  };

  const {
    isKeyPressed: { Shift: isShiftPressed },
  } = useKey("document", ["Shift"]);

  const setGroupPillFocus = (index: number) => {
    if (divContainer.current && divRefs.current[index]) {
      divContainer.current.scrollLeft = divRefs.current[index]!.offsetLeft;
    }
  };

  // NEEDS REFACTOR

  return (
    <div className="w-full bg-gray8 h-full flex flex-col">
      <div className={`h-[${ROW_HEIGHT}px] relative`}>
        <div className="h-[40%] w-full bg-gray4 flex">
          <div className="flex h-full gap-1">
            {activeSeniority && (
              <div
                className="flex flex-col-reverse cursor-pointer"
                onClick={() => {
                  setSidebarState("sort");
                }}
              >
                <div
                  className={`${
                    sideBarState === "sort"
                      ? `${activeSeniority?.bgColor} text-white`
                      : `${activeSeniority?.bgColorLight} ${activeSeniority?.borderNoBottom} ${activeSeniority?.textColor}`
                  } rounded-t-lg text-xs text-nowrap h-fit pt-0.5 px-1 font-medium`}
                >
                  {activeSeniority.label}
                </div>
              </div>
            )}
            {!activeSeniority && (
              <div
                className="flex flex-col-reverse cursor-pointer"
                onClick={() => {
                  setSidebarState("sort");
                }}
              >
                <div
                  className={`${
                    sideBarState === "sort"
                      ? `bg-black text-white`
                      : `bg-white border-black border-x border-t text-black`
                  } rounded-t-lg text-[10px] text-nowrap h-fit pt-0.5 px-1 font-medium`}
                >
                  All
                </div>
              </div>
            )}
            <div
              className="flex flex-col-reverse"
              onClick={() => {
                setSidebarState("groups");
              }}
            >
              <div
                className={`rounded-t-lg text-[10px] text-nowrap h-fit pt-0.5 px-1 font-medium ${
                  sideBarState === "groups"
                    ? `${activeSeniority?.bgColor ?? "bg-black"} text-white`
                    : `${activeSeniority?.textColor ?? "text-black"} bg-white`
                } cursor-pointer`}
              >
                groups
              </div>
            </div>
            <div
              className="flex flex-col-reverse"
              onClick={() => {
                setSidebarState("tags");
              }}
            >
              <div
                className={`rounded-t-lg text-[10px] text-nowrap h-fit pt-0.5 px-1 font-medium ${
                  sideBarState === "tags"
                    ? `${activeSeniority?.bgColor ?? "bg-black"} text-white`
                    : `${activeSeniority?.textColor ?? "text-black"} bg-white`
                } cursor-pointer`}
              >
                #tags
              </div>
            </div>
          </div>
        </div>
        <div className="h-[60%]">
          {sideBarState === "sort" && (
            <div className="h-full flex items-center px-1.5">
              <HorizontalScrollContainer className={`!gap-x-1 py-1`}>
                <div
                  className={`${
                    doctorSort.name
                      ? `${activeSeniority?.bgColor ?? "bg-black"} text-white`
                      : `${activeSeniority?.textColor ?? "text-black"} bg-white`
                  } p-[2px] flex text-[10px] rounded-md gap-[2px] cursor-pointer`}
                  onClick={() => {
                    setDoctorSort({
                      name: !doctorSort.name
                        ? "asc"
                        : doctorSort.name === "asc"
                        ? "desc"
                        : null,
                      experience: null,
                      efficiency: null,
                    });
                  }}
                >
                  <div
                    className={`w-[20px] ${
                      doctorSort.name
                        ? "bg-white"
                        : activeSeniority?.bgColor ?? "bg-black"
                    } rounded-md flex justify-center items-center shadow-xl`}
                  >
                    <Sort
                      type={doctorSort.name === "desc" ? "down" : "up"}
                      pathFill={
                        doctorSort.name
                          ? activeSeniority?.colorCode ?? "black"
                          : "white"
                      }
                    />
                  </div>
                  Name
                </div>
                <div
                  className={`${
                    doctorSort.efficiency
                      ? `${activeSeniority?.bgColor ?? "bg-black"} text-white`
                      : `${activeSeniority?.textColor ?? "text-black"} bg-white`
                  } p-[2px] flex text-[10px] rounded-md gap-[2px] cursor-pointer`}
                  onClick={() => {
                    setDoctorSort({
                      efficiency: !doctorSort.efficiency
                        ? "asc"
                        : doctorSort.efficiency === "asc"
                        ? "desc"
                        : null,
                      name: null,
                      experience: null,
                    });
                  }}
                >
                  <div
                    className={`w-[20px] ${
                      doctorSort.efficiency
                        ? "bg-white"
                        : activeSeniority?.bgColor ?? "bg-black"
                    } rounded-md flex justify-center items-center shadow-xl relative`}
                  >
                    {doctorSort.efficiency === "desc" && (
                      <div className="absolute left-[1px]">
                        <HalfArrow
                          pathFill={activeSeniority?.colorCode ?? "black"}
                          type="down"
                        />
                      </div>
                    )}
                    <GridTwo
                      pathFill={
                        doctorSort.efficiency
                          ? activeSeniority?.colorCode ?? "black"
                          : "white"
                      }
                    />
                    {doctorSort.efficiency === "asc" && (
                      <div className="absolute right-[2px] top-[2px]">
                        <HalfArrow
                          pathFill={activeSeniority?.colorCode ?? "black"}
                          type="up"
                        />
                      </div>
                    )}
                  </div>
                  Efficiency
                </div>
                <div
                  className={`${
                    doctorSort.experience
                      ? `${activeSeniority?.bgColor ?? "bg-black"} text-white`
                      : `${activeSeniority?.textColor ?? "text-black"} bg-white`
                  } p-[2px] flex text-[10px] rounded-md gap-[2px] cursor-pointer`}
                  onClick={() => {
                    setDoctorSort({
                      experience: !doctorSort.experience
                        ? "asc"
                        : doctorSort.experience === "asc"
                        ? "desc"
                        : null,
                      name: null,
                      efficiency: null,
                    });
                  }}
                >
                  <div
                    className={`w-[20px] ${
                      doctorSort.experience
                        ? "bg-white"
                        : activeSeniority?.bgColor ?? "bg-black"
                    } rounded flex justify-center items-center shadow-xl relative`}
                  >
                    {doctorSort.experience === "desc" && (
                      <div className="absolute left-[1px]">
                        <HalfArrow
                          pathFill={activeSeniority?.colorCode ?? "black"}
                          type="down"
                        />
                      </div>
                    )}
                    <ChatBubbleStar
                      pathFill={
                        doctorSort.experience
                          ? activeSeniority?.colorCode ?? "black"
                          : "white"
                      }
                    />
                    {doctorSort.experience === "asc" && (
                      <div className="absolute right-[1px] top-[2px]">
                        <HalfArrow
                          pathFill={activeSeniority?.colorCode ?? "black"}
                          type="up"
                        />
                      </div>
                    )}
                  </div>
                  Experience
                </div>
              </HorizontalScrollContainer>
            </div>
          )}
          {sideBarState === "tags" && (
            <div className="h-full flex items-center px-1.5">
              <HorizontalScrollContainer
                className={`!gap-x-1 py-1 ${
                  isTagsLoading
                    ? "animate-pulseFast rounded-lg bg-lightGreen2"
                    : ""
                }`}
              >
                {tags?.map((tag: { value: string; _id: string }) => (
                  <div
                    key={tag._id}
                    className={`rounded-md px-2 text-[11px] font-bold drop-shadow-lg ${
                      doctorFilter.tags.includes(tag._id)
                        ? `${activeSeniority?.bgColor ?? "bg-black"} text-white`
                        : `${
                            activeSeniority?.textColor ?? "text-black"
                          } bg-white`
                    } cursor-pointer`}
                    onClick={() => {
                      if (doctorFilter.tags.includes(tag._id)) {
                        if (isShiftPressed) {
                          setDoctorFilter({
                            ...doctorFilter,
                            tags: doctorFilter.tags.filter(
                              (tagId) => tagId !== tag._id
                            ),
                          });
                        } else {
                          setDoctorFilter({
                            ...doctorFilter,
                            tags: doctorFilter.tags.length > 1 ? [tag._id] : [],
                          });
                        }
                      } else {
                        if (isShiftPressed) {
                          setDoctorFilter({
                            ...doctorFilter,
                            tags: [...doctorFilter.tags.concat([tag._id])],
                          });
                        } else {
                          setDoctorFilter({ ...doctorFilter, tags: [tag._id] });
                        }
                      }
                    }}
                  >
                    #{tag.value}
                  </div>
                ))}
              </HorizontalScrollContainer>
            </div>
          )}
          {sideBarState === "groups" && (
            <div className="h-full flex items-center px-1.5">
              <HorizontalScrollContainer
                isLoading={isGroupsLoading}
                className="!gap-x-1 py-1"
                containerRef={divContainer}
              >
                {groups?.map(
                  (
                    group: {
                      _id: string;
                      title: string;
                      subGroups: any[];
                      seniority: { id: number };
                    },
                    index: number
                  ) => (
                    <div
                      className={`${
                        doctorFilter.group === group._id
                          ? "bg-white"
                          : getSeniority(group.seniority.id)?.bgColor
                      } flex rounded-md drop-shadow-lg`}
                      ref={(elem) => {
                        divRefs.current[index] = elem;
                      }}
                    >
                      <div
                        key={group._id}
                        className={`${
                          activeDrawerGroup === group._id ||
                          doctorFilter.group === group._id
                            ? `${
                                getSeniority(group.seniority.id)?.bgColor
                              } text-white`
                            : `bg-white ${
                                getSeniority(group.seniority.id)?.textColor
                              }`
                        } ${
                          group.subGroups.length > 0
                            ? "rounded-l-md"
                            : "rounded-md"
                        } px-2 text-[11px] font-bold cursor-pointer`}
                        onClick={() => {
                          if (doctorFilter.group === group._id) {
                            setDoctorFilter({
                              ...doctorFilter,
                              group: undefined,
                              subGroups: [],
                            });
                          } else {
                            setDoctorFilter({
                              ...doctorFilter,
                              group: group._id,
                              subGroups: [],
                            });
                            setGroupPillFocus(index);
                          }
                          setActiveDrawerGroup("");
                        }}
                      >
                        {group.title}
                      </div>
                      <AnimatePresence>
                        {group.subGroups.length > 0 ? (
                          activeDrawerGroup === group._id ? (
                            <motion.div
                              key={group._id}
                              initial={{ width: 0 }}
                              animate={{ width: "auto" }}
                              exit={{ width: 0 }}
                              transition={{ type: "interia" }}
                              className="bg-white flex items-center gap-1 px-1"
                            >
                              <AnimatePresence>
                                {group.subGroups.map((sG) => (
                                  <motion.div
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    exit={{ opacity: 0 }}
                                    transition={{ type: "interia", delay: 0.3 }}
                                    key={sG._id}
                                    className={`${
                                      doctorFilter.subGroups.includes(sG._id)
                                        ? `bg-white ${activeSeniority?.border}`
                                        : "bg-gray8"
                                    } rounded px-2 text-[8px] font-bold h-fit cursor-pointer flex items-center ${
                                      getSeniority(group.seniority.id)
                                        ?.textColor
                                    }`}
                                    onClick={() => {
                                      if (
                                        doctorFilter.subGroups.includes(sG._id)
                                      ) {
                                        if (isShiftPressed) {
                                          setDoctorFilter({
                                            ...doctorFilter,
                                            subGroups:
                                              doctorFilter.subGroups.filter(
                                                (sGId) => sGId !== sG._id
                                              ),
                                          });
                                        } else {
                                          setDoctorFilter({
                                            ...doctorFilter,
                                            subGroups:
                                              doctorFilter.subGroups.length > 1
                                                ? [sG._id]
                                                : [],
                                          });
                                        }
                                      } else {
                                        if (isShiftPressed) {
                                          setDoctorFilter({
                                            ...doctorFilter,
                                            subGroups:
                                              doctorFilter.subGroups.concat([
                                                sG._id,
                                              ]),
                                          });
                                        } else {
                                          setDoctorFilter({
                                            ...doctorFilter,
                                            subGroups: [sG._id],
                                          });
                                        }
                                      }
                                    }}
                                  >
                                    <div>{sG.title}</div>
                                    {doctorFilter.subGroups.includes(
                                      sG._id
                                    ) && (
                                      <div
                                        className={`h-[5px] w-[5px] ${
                                          getSeniority(group.seniority.id)
                                            ?.bgColor
                                        } rounded-full relative left-1`}
                                      ></div>
                                    )}
                                  </motion.div>
                                ))}
                              </AnimatePresence>
                            </motion.div>
                          ) : (
                            <div className="w-[1px]"></div>
                          )
                        ) : (
                          <></>
                        )}
                      </AnimatePresence>
                      {group.subGroups.length > 0 && (
                        <div
                          className={`${
                            activeDrawerGroup === group._id ||
                            doctorFilter.group === group._id
                              ? `${getSeniority(group.seniority.id)?.bgColor}`
                              : "bg-white"
                          } flex items-center rounded-r-md px-1 cursor-pointer`}
                          onClick={() => {
                            if (activeDrawerGroup === group._id) {
                              setActiveDrawerGroup("");
                            } else {
                              setDoctorFilter({
                                ...doctorFilter,
                                group: group._id,
                                subGroups: [],
                              });
                              setGroupPillFocus(index);
                              setActiveDrawerGroup(group._id);
                            }
                          }}
                        >
                          <CarotTwo
                            type={
                              activeDrawerGroup === group._id ? "left" : "right"
                            }
                            pathFill={
                              activeDrawerGroup === group._id ||
                              doctorFilter.group === group._id
                                ? "white"
                                : getSeniority(group.seniority.id)?.colorCode ??
                                  ""
                            }
                            size={{ width: "3px", height: "5px" }}
                          />
                        </div>
                      )}
                    </div>
                  )
                )}
              </HorizontalScrollContainer>
            </div>
          )}
        </div>
      </div>
      <div
        className={`overflow-y-auto px-1 flex-grow h-0 no-scrollbar ${
          isDoctorsFetching ? "animate-pulseFast" : ""
        }`}
        ref={(ref) => {
          scrollRef!.current.divSideBar = ref;
        }}
      >
        {isDoctorsLoading ? (
          <InfinitySpin width="200px" color="#67823A" />
        ) : (
          doctors?.map((doctor: Doctor, index: number) => (
            <div
              className={`h-[${ROW_HEIGHT}px] flex items-center`}
              key={doctor._id}
            >
              <div className="h-[90%] w-full">
                <DoctorCard
                  doctor={doctor}
                  name={doctor.user.name}
                  seniority={doctor.seniority.id}
                  nickName={doctor.user.nickName}
                  pos={(() => {
                    if (
                      index <
                      Math.ceil(
                        ((scrollRef!.current.divSideBar?.clientHeight ?? 1000) +
                          (scrollRef!.current.divSideBar?.scrollTop ?? 1000)) /
                          ROW_HEIGHT
                      ) -
                        Math.ceil(360 / ROW_HEIGHT)
                    )
                      return "top";
                    return "bottom";
                  })()}
                />
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default SideBar;
