import { useCallback, useEffect, useRef } from "react";
import { DateTime } from "luxon";

import { useTableViewContext } from "@/hooks/context/useTableViewContext";
import { ROW_HEIGHT, ROW_WIDTH, TIME_ZONE } from "@/constants";
import { isDatesEqual } from "@/utils/date";

import Cell from "./Cell";
import { Doctor } from "@/interface/doctor";
import { useDoctors, useTableData } from "./hooks/data";
import { Shift } from "@/interface/shift";
import { Schedule } from "@/interface/schedule";
import { formatDateAPI } from "@/utils/formatDate";
import { useSeniority } from "@/store/seniority.state";
import { useLocation } from "@/store/location.store";
import { InfinitySpin } from "react-loader-spinner";
import { useGetDates } from "@/hooks/table/useGetDates";
import useSlidingAnimation from "./hooks/slidingAnimation";
import ShiftsColumn from "./ShiftsColumn";

// Add react window along with infinite scroll

const Table = () => {
  const {
    month,
    year,
    activeDate,
    setActiveDate,
    isShiftTimeToggle,
    scrollRef,
    onScrollHandlers,
    isDayOffFilter,
    isLocationFilter,
    setTableParams,
    tableState,
    type,
  } = useTableViewContext();

  const { activeId: activeLocationId } = useLocation();
  const { activeId: activeSeniorityId } = useSeniority();

  const getIsActiveHeader = ({ date }: { date: Date }) => {
    return (
      Boolean(!activeDate) || (activeDate && isDatesEqual(date, activeDate))
    );
  };

  const getIsActiveCell = ({
    date,
    locationIds,
    isDayOff,
    calendarData,
    noShiftsAssigned,
  }: {
    date: Date;
    locationIds: string[];
    isDayOff: boolean;
    calendarData: any;
    noShiftsAssigned: boolean;
  }) => {
    if (activeDate) {
      return isDatesEqual(date, activeDate);
    }

    if (isDayOffFilter && isLocationFilter && !activeLocationId) {
      return true;
    }

    if (isDayOffFilter || isLocationFilter) {
      return (
        (isDayOffFilter &&
          (isDayOff || (calendarData?.isFinalized && noShiftsAssigned))) ||
        (isLocationFilter &&
          ((!activeLocationId && locationIds.length > 0) ||
            locationIds.includes(activeLocationId)))
      );
    }

    return Boolean(!activeDate);
  };

  const { doctors, isLoading: isDoctorsLoading } = useDoctors();

  const {
    schedulesByDoctor,
    shiftsByMonth,
    dayOffsByMonth,
    calendar,
    isTableDataFetching,
    isTableDataLoading,
  } = useTableData({ month, year, type });

  const getCalendarData = useCallback(
    (date: Date) => {
      if (activeLocationId && activeSeniorityId) {
        return calendar?.[
          DateTime.fromJSDate(date).setZone(TIME_ZONE).toUTC().toISO()!
        ];
      } else {
        return null;
      }
    },
    [activeLocationId, activeSeniorityId, calendar]
  );

  const tableRef = useRef<HTMLDivElement | null>(null);

  const overlap = tableRef.current
    ? Math.ceil(tableRef.current.clientWidth / ROW_WIDTH[tableState].value)
    : 9;

  const { dates, monthData } = useGetDates({
    month,
    year,
    overlap,
  });

  useSlidingAnimation({
    tableRef,
    dates,
    monthData,
    overlap,
    schedulesByDoctor,
    shiftsByMonth,
    dayOffsByMonth,
  });

  useEffect(() => {
    if (
      scrollRef &&
      scrollRef.current.divTable &&
      schedulesByDoctor &&
      shiftsByMonth &&
      dayOffsByMonth
    ) {
      scrollRef.current.divTable.addEventListener(
        "scroll",
        onScrollHandlers.table
      );
      const elemCopy = scrollRef!.current.divTable;
      return () => {
        elemCopy?.removeEventListener("scroll", onScrollHandlers.table);
      };
    }
  }, [dayOffsByMonth, schedulesByDoctor, shiftsByMonth]);

  const getIsDayOff = (doctor: Doctor, date: Date) => {
    return (
      dayOffsByMonth &&
      Boolean(
        dayOffsByMonth[formatDateAPI(date)]?.doctors?.includes(doctor._id)
      )
    );
  };

  const getActiveColorHeader = (dateTime: DateTime) => {
    if (dateTime.month % 2 === 0) {
      return dateTime.weekday === 6 || dateTime.weekday === 7
        ? "bg-green3"
        : "bg-pink1";
    } else {
      return dateTime.weekday === 6 || dateTime.weekday === 7
        ? "bg-teal7"
        : "bg-maroon4";
    }
  };

  return (
    <div
      className="w-full overflow-x-scroll h-full bg-gray4 small-scrollbar-y"
      ref={tableRef}
    >
      {!isDoctorsLoading && !isTableDataLoading ? (
        <table className="table-fixed border-spacing-x-[2px] border-separate h-full">
          <thead>
            <tr className="block">
              {dates.map((date, index) => {
                const dateTime = DateTime.fromJSDate(date).setZone(TIME_ZONE);
                return (
                  <th key={index}>
                    <div
                      className={`${
                        getIsActiveHeader({ date })
                          ? getActiveColorHeader(dateTime)
                          : "bg-gray7"
                      } rounded-t-lg ${
                        ROW_WIDTH[tableState].className
                      } h-[${ROW_HEIGHT}px] flex justify-center items-center relative text-white cursor-pointer font-normal`}
                      onClick={() => {
                        if (activeDate && isDatesEqual(date, activeDate)) {
                          setActiveDate(undefined);
                          setTableParams({ activeDate_: null });
                        } else {
                          setActiveDate(date);
                          setTableParams({ activeDate_: date });
                        }
                      }}
                    >
                      <div className="absolute left-1 top-0 text-sm">
                        {dateTime.toFormat("MMM").toUpperCase()}
                      </div>
                      <div className="text-3xl">{dateTime.day}</div>
                      <div className="vertical-text -scale-100 absolute right-0 text-lg">
                        {dateTime.toFormat("EEE")}
                      </div>
                    </div>
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody
            className="block overflow-y-scroll h-full small-scrollbar"
            ref={(ref) => {
              scrollRef!.current.divTable = ref;
            }}
          >
            {tableState === "location" && (
              <tr className="table">
                {dates.map((date) => {
                  return (
                    <td
                      className={`${ROW_WIDTH[tableState].className} h-full align-top`}
                    >
                      <ShiftsColumn
                        date={date}
                        isFetching={isTableDataFetching}
                        shifts={
                          shiftsByMonth?.[formatDateAPI(date)]?.filter(
                            (shift: {
                              location: { _id: string };
                              seniority: { id: number };
                            }) => {
                              return (
                                (activeLocationId === "" ||
                                  shift.location._id === activeLocationId) &&
                                (!activeSeniorityId ||
                                  shift.seniority.id === activeSeniorityId)
                              );
                            }
                          ) ?? []
                        }
                      />
                    </td>
                  );
                })}
              </tr>
            )}
            {tableState === "users" &&
              doctors?.map((doctor: Doctor) => {
                return (
                  <tr className="table" key={doctor._id}>
                    {dates.map((date, indexInner) => {
                      const scheduleIds: string[] = [];
                      const isDayOff = getIsDayOff(doctor, date);
                      const shifts: Shift[] = isDayOff
                        ? []
                        : shiftsByMonth?.[formatDateAPI(date)]?.filter(
                            (shift: Shift) => {
                              const schedule: Schedule | undefined =
                                schedulesByDoctor?.[formatDateAPI(date)]?.[
                                  doctor._id
                                ]?.find((schedule: Schedule) => {
                                  return shift._id === schedule.shift;
                                });
                              if (schedule) {
                                scheduleIds.push(schedule._id);
                              }
                              return Boolean(schedule);
                            }
                          ) ?? [];

                      const calendarData = getCalendarData(date);

                      return (
                        <td key={indexInner}>
                          <div
                            className={`${ROW_WIDTH[tableState].className} h-[${ROW_HEIGHT}px] border-b-green12 border-b-[1px]`}
                          >
                            <Cell
                              isFetching={isTableDataFetching}
                              isActive={getIsActiveCell({
                                date,
                                locationIds: shifts.map(
                                  (shift) => shift.location._id
                                ),
                                isDayOff,
                                calendarData,
                                noShiftsAssigned: shifts.length === 0,
                              })}
                              shifts={shifts}
                              slotIndexes={shifts.map((shift) =>
                                shift.slots.findIndex((slot) =>
                                  scheduleIds.includes(slot.schedule?._id)
                                )
                              )}
                              isOff={
                                calendarData?.isFinalized && shifts.length === 0
                                  ? true
                                  : isDayOff
                              }
                              doctorSeniority={doctor.seniority.id}
                              isTimeShowExt={isShiftTimeToggle}
                            />
                          </div>
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            <tr
              className="table"
              style={{
                height: `calc(100% - ${ROW_HEIGHT * doctors?.length}px)`,
              }}
            >
              {dates.map((date, index) => {
                return (
                  <td className="h-full" key={index}>
                    <div
                      className={`${
                        getIsActiveHeader({ date }) ? "bg-white" : "bg-gray8"
                      } w-[135px] h-full`}
                    ></div>
                  </td>
                );
              })}
            </tr>
          </tbody>
        </table>
      ) : (
        <div className="h-full flex justify-center items-center w-[80vw]">
          <div>
            <InfinitySpin width="200px" color="#67823A" />
          </div>
        </div>
      )}
    </div>
  );
};

export default Table;
